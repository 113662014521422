import React, { } from "react"

const Footer = ( { className, text } ) => {

    return (
        <div className={`footer ${className}`} dangerouslySetInnerHTML={ { __html: text } } />
    )

}



export default Footer;