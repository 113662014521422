import React, { useState, useEffect } from 'react';
import QrCode from './../images/svg-home.svg';


const Home = ( { pathname } ) => {

  return (
    <div className="container">
        <div className="title-wrapper">
            <h1>Witaj na stronie <strong>e-etykieta.com</strong></h1>
        </div>
        <div className="qr">
            <img src={QrCode} alt="qr" />
        </div>
        <div className="footer-wrapper">
            <p>Administratorem strony jest <a href="https://zpprw.pl/" target="_blank">Związek Pracodawców Polska Rada Winiarstwa</a></p>
        </div>
    </div>
  );

}

export default Home;
