import { Routes, Route, Link, useLocation } from "react-router-dom";
import ProductPage from './pages/Product';
import HomePage from './pages/Home';
import Layout from './components/Layout';

function App() {
  let location = useLocation();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={ <HomePage />} />
        <Route path="/:name" element={ <ProductPage pathname={location.pathname} /> } />
      </Routes>
    </Layout>
  );
} 


export default App;
