import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/header';
import Footer from '../components/footer';
import Table from '../components/table';
import Icons from '../components/icons';
import ApiUrl from '../services/ApiUrl';
import getTranslatesByKey from '../services/getTranslatesByKey';
import getDataFromUrl from '../services/getDataFromUrl';

const Product = ( { pathname } ) => {
  let [ load, setLoad ] = useState(false);
  let [ activeLang, setActiveLang ] = useState();
  let [ data, setData ] = useState(false);
  let [ translates, setTranslates ] = useState(false);
  let [ relations, setRelations ] = useState(false);

  useEffect(() => {
    if(!load) {
      getDataPage();
      setLoad(true);
    }
  }, [load])

  const getDataPage = async(slug = false) => {
    let key= getDataFromUrl("key");
    let ver= getDataFromUrl("ver");
    let userLangBrowser = false;
    if(!key && !slug) {
      userLangBrowser = navigator.language || navigator.userLanguage; 
      userLangBrowser = userLangBrowser.slice(userLangBrowser.indexOf('-') + 1).toUpperCase();
      if(userLangBrowser == "DA") {
        userLangBrowser = "DK";
      }
    }
    if(!slug) {
      slug = pathname.split("/")[1];
    }
    if(ver) {
      await axios({
        method: 'get',
        url: `${ApiUrl()}/getVersionWeb?slug=${slug}&ver=${ver}`,
      }).then(response => {
        let res = JSON.parse(response.request.response);
        if(res.status == "success") {
          setData(res?.data[0]);
          let langShortcode = {
            slug: false,
            shortcode: "PL",
            name: false,
          };
          if(res?.data[0]?.language?.shortcode) {
            langShortcode = {
              slug: res?.data[0]?.slug,
              shortcode: res?.data[0]?.language?.shortcode,
              name: res?.data[0]?.language?.name
            }
          }
          setRelations(res?.data[0].relations);
          if(langShortcode) {
            setActiveLang(langShortcode);
            getTranslates(langShortcode.shortcode); 
          }
        }
      });
    }else{
      await axios({
        method: 'get',
        url: `${ApiUrl()}/getPageBySlugWeb?slug=${slug}&key=${key}&userLangBrowser=${userLangBrowser}`,
      }).then(response => {
        let res = JSON.parse(response.request.response);
        if(res.status == "success") {
          setData(res?.data[0]);
          let langShortcode = {
            slug: false,
            shortcode: "PL",
            name: false,
          };
          if(res?.data[0]?.language?.shortcode) {
            langShortcode = {
              slug: res?.data[0]?.slug,
              shortcode: res?.data[0]?.language?.shortcode,
              name: res?.data[0]?.language?.name
            }
          }
          setRelations(res?.data[0].relations);
          if(langShortcode) {
            setActiveLang(langShortcode);
            getTranslates(langShortcode.shortcode); 
          }
        }
      });
    }
  }

  const getTranslates = async(langShortcode) => {
    await axios({
      method: 'get',
      url: `${ApiUrl()}/getTranslatesWeb?langShortcode=${langShortcode}`,
    }).then(response => {
      let res = JSON.parse(response.request.response);
      setTranslates(res.data);
    });
  }

  const getTranslate = (key) => {
    if(translates) {
      return getTranslatesByKey(translates, key);
    }
  }

  return (
    <>
    {data && translates &&
      <>
        <div className="col desktop">
          <div className="fix">
            {data.image_pc &&
              <div className="image-wrapper">
                <img src={data.image_pc?.src} alt={data.title} />
              </div>
            }
          </div>
        </div>
        <div className="col">
          <Header activeLang={activeLang} setLang={(val) => { setActiveLang(val); getDataPage(val.slug) } } title={data.title} relations={relations} />
          {(data.image_pc || data.image_mobile) &&
          <div className={`image-wrapper mobile ${data.choose_type_image_mobile == 'cover' ? 'avatar' : ''}`}>
            {data.choose_type_image_mobile == "normal" && data.image_mobile &&
              <img src={data.image_mobile?.src} alt={data.title} />
            }
            {data.choose_type_image_mobile == "cover" && data.image_mobile &&
              <img src={data.image_mobile} alt={data.title} />
            }
            {(data.choose_type_image_mobile == "none" || !data.choose_type_image_mobile || data.choose_type_image_mobile == "0") && data.image_pc &&
              <img src={data.image_pc?.src} alt={data.title} />
            }
          </div>
          }
          <div className="content-wrapper">
            {data.type_wine &&
              <h2>{data.type_wine}</h2>
            }
            {data.product_info &&
              <>
                <p className="title">{getTranslate("info_product")}</p>
                <div dangerouslySetInnerHTML={ { __html: data.product_info } } />
              </>
            }
            {data.ingredients &&
              <>
                <p className="title">{getTranslate("ingredients")}</p>
                <div dangerouslySetInnerHTML={ { __html: data.ingredients } } />
              </>
            }
          </div>
          <Footer className="desktop" text={data.company.information} />
        </div>
        <div className="col">
          <div className={`fix ${!data.iconsNormal && !data.iconsSO ? 'disabled-fix' : ''}`}>
            <Table data={data} getTranslate={(key) => getTranslate(key)} />
            <Icons iconsNormal={data.iconsNormal} iconsSO={data.iconsSO} />
          </div>
        </div>
        <div className="col mobile">
          <Footer text={data.company.information} />
        </div>
      </>
    }
    </>
  );

}

export default Product;
