import React, { useState, useEffect } from "react"
import dropdown from './../../images/dropdown.svg';
import dropdownBlack from './../../images/dropdown-black.svg';

const Header = ( { setLang, activeLang, title, relations } ) => {
    let [ load, setLoad ] = useState(false);
    let [ activeDrop, setActiveDrop ] = useState(false);
    let [ langs, setLangs ] = useState([]);

    useEffect(() => {
        if(!load) {
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(load && relations) {
            getLangs();
            setLoad(true);
        }
    }, [load, relations])

    const getLangs = () => {
        let newLangs = [];
        relations && relations.map(( relation, index ) => {
            newLangs = [...newLangs, {
                slug: relation.slug,
                shortcode: relation.language.shortcode,
                name: relation.language.name
            }]
        });
        setLangs(newLangs);
    }

    return (
        <div className="header">
            <div className="select-wrapper">
                <div className={"selected" + (activeDrop ? " active" : "")}>
                    <p onClick={() => setActiveDrop(!activeDrop)}>{activeLang.shortcode}</p>
                    {load && langs && langs.length > 0 &&
                        <>
                            <img className="desktop" onClick={() => setActiveDrop(!activeDrop)} src={dropdown} alt="dropdown" />
                            <img className="mobile" onClick={() => setActiveDrop(!activeDrop)} src={dropdownBlack} alt="dropdown" />
                            <div className="options">
                                {langs && langs.map((lang, index) => (
                                    <p key={index} onClick={() => { setLang(lang); setActiveDrop(false) }}>{lang.shortcode}</p>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </div>
            {title &&
                <h1>{title}</h1>
            }
        </div>
    )

}



export default Header;