import React, { } from "react"

const Layout = ( { children } ) => {

    return (
        <div className="layout">
            { children }
        </div>
    )

}



export default Layout;