import React, { } from "react"

const Table = ( {data, getTranslate} ) => {

    return (
        <div className="table-wrapper">
            <p className="title">{getTranslate("value_product")}</p>
            <div className="table">
            <div className="row">
                <div className="col"></div>
                <div className="col">{getTranslate("per")} 100 ml</div>
                {/* <div className="col">w 125 ml</div> */}
            </div>
            <div className="row">
                <div className="col">{getTranslate("energy_value")}</div>
                <div className="col">{data.value_kj} kJ<br />{data.value_kcal} kcal</div>
                {/* <div className="col">{Math.round(data.value_kj * 1.25)} kJ<br />{Math.round(data.value_kcal * 1.25)} kcal</div> */}
            </div>
            <div className="row">
                <div className="col">{getTranslate("fat")}<small>{getTranslate("including_fatty acids")}</small></div>
                <div className="col">{data.value_fat} g<br />{data.value_fat_saturated} g</div>
                {/* <div className="col">{Math.round(data.value_fat * 1.25)}g<br />{Math.round(data.value_fat_saturated * 1.25)}0g</div> */}
            </div>
            <div className="row">
                <div className="col">{getTranslate("carbohydrates")}<br /><small>{getTranslate("including_sugars")}</small></div>
                <div className="col">{data.value_carbohydrates} g<br />{data.value_carbohydrates_sugars} g</div>
                {/* <div className="col">{Math.round(data.value_carbohydrates * 1.25)}g<br />{Math.round(data.value_carbohydrates_sugars * 1.25)}g</div> */}
            </div>
            <div className="row">
                <div className="col">{getTranslate("protein")}</div>
                <div className="col">{data.value_protein} g</div>
                {/* <div className="col">{Math.round(data.value_protein * 1.25)}g</div> */}
            </div>
            <div className="row">
                <div className="col">{getTranslate("salt")}</div>
                <div className="col">{data.value_salt} g</div>
                {/* <div className="col">{Math.round(data.value_salt * 1.25)}g</div> */}
            </div>
            </div>
        </div>
    )

}



export default Table;