const getTranslatesByKey = (items, key) => {
    if(items && items.length > 0 && key) {
        let item = items.filter(item => item.meta_key == key ? item : null);
        let result = `I can't find this translate`;
        if(item && item.length > 0) {
            result = item[0].value;
        }
        return result;
    }else{
        return `I can't find this translate`;
    }
} 

export default getTranslatesByKey;