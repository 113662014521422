import React from "react"

const Icons = ( { iconsNormal, iconsSO } ) => {
    let compareIcons = false;
    
    if(iconsNormal && iconsSO) {
        compareIcons = iconsNormal.concat(iconsSO);
    }else if(iconsNormal && !iconsSO) {
        compareIcons = iconsNormal
    }else if(iconsSO && !iconsNormal) {
        compareIcons = iconsSO
    }

    return (
        <div className="icons-wrapper">
            {compareIcons && compareIcons.map((icon, i) => (
                <>
                {i < 4 &&
                    <div className={`icon ${icon.includes("/so/") ? 'big' : ''}`} key={i}>
                        <img src={icon} alt="Ikona" />
                    </div>
                }
                </>
            ))}
        </div>
    )
}
    
    
    
export default Icons;